import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import { deepPurple } from '@mui/material/colors';

const customTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
          main: '#27336e',
        },
        secondary: {
          main: '#f0f0f0',
        },
        text: {
            primary: '#0a0a0a',
            secondary: '#f0f0f0',
        },
    },
    typography: {
        fontFamily: 'Montserrat',
    },
    transitions: {
        duration: {
            standard: '0.5s'
        }
    },
    components: {
        'MuiTabs': {
            styleOverrides: {
                'flexContainer': {
                    'height': '100%'
                }
            }
        },
        
    }
});

export default customTheme;