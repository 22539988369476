import { Grid } from "@mui/material";
import NavBar from "../NavBar";
// import ItemCard from "../ItemCard";
import ItemPage from "../ItemPage";
import TabPanel from "../TabPanel";


import workwear_image_0 from "../../assets/clothing/test_image_workwear.png";
import workwear_image_1 from "../../assets/clothing/workwear/clothing-workwear-1.png";
import workwear_image_2 from "../../assets/clothing/workwear/clothing-workwear-2.png";
import workwear_image_3 from "../../assets/clothing/workwear/clothing-workwear-3.png";
import workwear_logo from "../../assets/clothing/workwear/carhartt_logo.png";

import health_image_0 from "../../assets/clothing/healthcare/clothing-health-0.webp";
import health_image_1 from "../../assets/clothing/healthcare/clothing-health-1.webp";
import health_image_2 from "../../assets/clothing/healthcare/clothing-health-2.webp";
import health_image_3 from "../../assets/clothing/healthcare/clothing-health-3.webp";
import health_logo from "../../assets/clothing/healthcare/clothing-health-logo.png";

import fashion_image_0 from "../../assets/clothing/fashion/clothing-fashion-0.webp";
import fashion_image_1 from "../../assets/clothing/fashion/clothing-fashion-1.jpg";
import fashion_image_2 from "../../assets/clothing/fashion/clothing-fashion-2.webp";
import fashion_image_3 from "../../assets/clothing/fashion/clothing-fashion-3.webp";
import fashion_logo from "../../assets/clothing/fashion/clothing-fashion-logo.png";

import { useState } from "react";

export default function Clothing(props) {

    const workwearItem = {
        images: [ workwear_image_0, workwear_image_1, workwear_image_2, workwear_image_3 ],
        title: 'Carhartt Work Jacket',
        description: "Engineered for high warmth in severly cold conditions. An update to one of Carhartt's most popular Active Jacs, the J130, this men's jacket was designed using feedback from the Carhartt Crew.",
        price: '119.99',
        logo: workwear_logo,
    }
    
    const healthcareItem = {
        images: [ health_image_0, health_image_1, health_image_2, health_image_3 ],
        title: 'Arrival T-Shirt',
        description: 'The Arrival T-Shirt is your admission to a new, purposeful approach to training. Created to encourage you to aspire more, perform more and achieve more, this gym top boasts fundamental performance technology for next-level results, with sweat-wicking technology and a lightweight material that enables maximum movement and exertion.',
        price: '20.99',
        logo: health_logo,
    }

    const fashionItem = {
        images: [ fashion_image_0, fashion_image_1, fashion_image_2, fashion_image_3 ],
        title: 'The Polo Coat',
        description: "The Polo coat is named for its popularity among English polo players who would warm up in the garment between matches. Ralph Lauren's version maintains iconic details, such as the double-breasted silhouette and belted back, while elevating it with American craftsmanship and custom-woven camel hair from the famed Joshua Ellis mill. ",
        price: '2499.99',
        logo: fashion_logo,
    }


    const [currentTab, setCurrentTab] = useState(0);

    return(
        <>
            <NavBar currentTab={currentTab} setCurrentTab={setCurrentTab}/>
            <Grid container
                sx={{
                    height: '90vh'
                }}
                py={5}
            >
                <TabPanel value={currentTab} index={0}>
                    <ItemPage
                        smallImages={workwearItem.images} 
                        title={workwearItem.title} 
                        description={workwearItem.description}
                        price={workwearItem.price}
                        logo={workwearItem.logo}
                        type={'clothing'}
                        industry={'workwear'}
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    <ItemPage
                        smallImages={healthcareItem.images} 
                        title={healthcareItem.title} 
                        description={healthcareItem.description}
                        price={healthcareItem.price}
                        logo={healthcareItem.logo}
                        type={'clothing'}
                        industry={'healthcare'}
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <ItemPage
                        smallImages={fashionItem.images} 
                        title={fashionItem.title} 
                        description={fashionItem.description}
                        price={fashionItem.price}
                        logo={fashionItem.logo}
                        type={'clothing'}
                        industry={'fashion'}
                    />
                </TabPanel>
            </Grid>
        </>
    );
}