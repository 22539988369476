import {
    Grid
} from "@mui/material";

import NavBar from "../NavBar";
import ItemPage from "../ItemPage";
import TabPanel from "../TabPanel";

import workwear_image_0 from "../../assets/footwear/workwear/footwear-workwear-0.jpg";
import workwear_image_1 from "../../assets/footwear/workwear/footwear-workwear-1.jpg";
import workwear_image_2 from "../../assets/footwear/workwear/footwear-workwear-2.jpg";
import workwear_image_3 from "../../assets/footwear/workwear/footwear-workwear-3.jpg";
import workwear_logo from "../../assets/footwear/workwear/footwear-workwear-logo.png";

import health_image_0 from "../../assets/footwear/healthcare/footwear-health-0.jpg";
import health_image_1 from "../../assets/footwear/healthcare/footwear-health-1.webp";
import health_image_2 from "../../assets/footwear/healthcare/footwear-health-2.jpg";
import health_image_3 from "../../assets/footwear/healthcare/footwear-health-3.jpg";
import health_logo from "../../assets/footwear/healthcare/footwear-health-logo.svg";

import fashion_image_0 from "../../assets/footwear/test_image_footwear.webp";
import fashion_image_1 from "../../assets/footwear/fashion/footwear-fashion-1.webp";
import fashion_image_2 from "../../assets/footwear/fashion/footwear-fashion-2.webp";
import fashion_image_3 from "../../assets/footwear/fashion/footwear-fashion-3.webp";
import fashion_logo from "../../assets/footwear/fashion/new_balance_logo.png";

import { useState } from "react";

export default function Footwear(props) {

    const workwearItem = {
        images: [ workwear_image_0, workwear_image_1, workwear_image_2, workwear_image_3 ],
        title: "TRADESMAN CSA Safety Waterproof Toe Boot",
        description: "Bring durable, versatile, hiker-inspired performance to the jobsite with these lightweight, CSA-approved 6-inch boots purpose-built for medium-duty work. An innovative last design maintains all-day comfort as the foot expands, while the Red Wing waterproof upper protects from wet weather. The puncture-resistant VersaTrax outsole delivers best-in-class slip, oil/gas and chemical resistance. ",
        price: '239.99',
        logo: workwear_logo,
    }
    
    const healthcareItem = {
        images: [ health_image_0, health_image_1, health_image_2, health_image_3 ],
        title: 'Knee-High Compression Socks',
        description: 'Companions have a mild level of true graduated compression™ designed to ease moderate aches & pains and discomfort. Common use cases: long days on your feet, endurance sports, travel, recovery, swelling, spider & varicose veins.',
        price: '81.99',
        logo: health_logo,
    }

    const fashionItem = {
        images: [ fashion_image_0, fashion_image_1, fashion_image_2, fashion_image_3 ],
        title: "Men's Made in USA 990v6",
        description: "The designers of the first 990 were tasked with creating the single best running shoe on the market. The MADE in USA 990v6 embraces this original mandate, with a series of performance-inspired updates. ",
        price: '199.99',
        logo: fashion_logo,
    }


    const [currentTab, setCurrentTab] = useState(0);


    return(
        <>
            <NavBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <Grid container
                sx={{
                    height: '90vh'
                }}
                py={5}
            >
                <TabPanel value={currentTab} index={0}>
                    <ItemPage
                        smallImages={workwearItem.images} 
                        title={workwearItem.title} 
                        description={workwearItem.description}
                        price={workwearItem.price}
                        logo={workwearItem.logo}
                        type={'footwear'}
                        industry={'workwear'}
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    <ItemPage
                        smallImages={healthcareItem.images} 
                        title={healthcareItem.title} 
                        description={healthcareItem.description}
                        price={healthcareItem.price}
                        logo={healthcareItem.logo}
                        type={'footwear'}
                        industry={'healthcare'}
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <ItemPage
                        smallImages={fashionItem.images} 
                        title={fashionItem.title} 
                        description={fashionItem.description}
                        price={fashionItem.price}
                        logo={fashionItem.logo}
                        type={'footwear'}
                        industry={'fashion'}
                    />
                </TabPanel>
            </Grid>
        </>
    );
}