import { 
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    useMediaQuery
} from "@mui/material";

import {
    Close,
    Info
} from "@mui/icons-material";

import { useEffect, useState } from "react";

import { useTheme } from '@mui/material/styles';
import { useShopContext } from "../context";

import QRCode from "react-qr-code";

export default function Widget(props) {
    const { 
        user_id,
        hand_user_id,
        foot_user_id,
        shop_id, 
        result, 
        setResult, 
        widget_url, 
        user_measurements, 
        setUserMeasurements, 
        size_charts 
    } = useShopContext();

    const [openDialog, setOpenDialog] = useState(false);
    const [processStatus, setProcessStatus] = useState("process_waiting");
    const [intervalID, setIntervalID] = useState(null);
    let isFetching = false;
    let employee_id;
    switch (props.type) {
        case 'clothing':
            employee_id = user_id;
            break;

        case 'handwear':
            employee_id = hand_user_id;
            break;

        case 'footwear':
            employee_id = foot_user_id;
            break;
        default:
            employee_id = user_id;
            break;
    }

    const url = "https://dashboard.test.esenca.app/get_process_status";

    const handleClickOpenDialog = () => {
        setOpenDialog(true);

        const handleFetch = async (url) => {
            // const client_id = "mtm112ebd57-7db2-46c6-ae51-ab4d68f50744";

            console.log(employee_id, shop_id)

            if (!isFetching) {

                console.log("Fetching...");

                isFetching = true;
                try {

                    let response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            "Accept": "application/json", 
                            "Content-Type": 'application/json',
                        },
                        body: JSON.stringify({
                            user_id: shop_id,
                            client_id: employee_id,
                        })
                    });
            
                    let result = await response.json();
        
                    console.log(result);
        
                    let status = result['status'];
                    console.log(status)

                    switch (status) {
                        case 'scan_not_started':
                            console.log("entered scan_not_started branch");
                            setProcessStatus('process_waiting')
                            break;

                        case 'scan_in_progress':
                            console.log("entered scan_in_progress branch");
                            setProcessStatus('process_scanning')
                            break;

                        case 'scan_errors':
                            console.log("entered scan_errors branch");
                            setProcessStatus('process_error')
                            break;
                    
                        case 'scan_successful':
                            console.log("entered scan_successful branch");
                            setProcessStatus('process_success')
                            break;
                        default:
                            console.log("entered default branch");
                            break;
                    }
                } catch (error) {
                    console.error('Fetch interval error:', error);
                } finally {
                    isFetching = false;
                }
            } else {
                console.log('Previous fetch is still in progress.');
            }
        }

        if (!intervalID) {
            let newIntervalID = setInterval(() => handleFetch(url), 2000);
            setIntervalID(newIntervalID);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);

        clearInterval(intervalID);
        setIntervalID(null);

        setProcessStatus('process_waiting');
    };

    function recommendSize(fullChart, client) {

        let size_chart = fullChart;
    
        let to_compare = [];
    
        let sizes = size_chart.sizes
    
        let keys = Object.keys(size_chart)
    
        for(let i = 0; i < keys.length; i++){
            if(client[keys[i]]){
                let measurement = client[keys[i]]
                let arr = size_chart[keys[i]]
    
                for(let j = 0; j < arr.length; j++){
                    if(measurement > arr[j]){
                        // console.log(arr[j],j,measurement)
                    } else {
                        to_compare.push(j)
                        break
                    }
                }
                if(measurement > arr[arr.length -1 ]){
                    to_compare.push(arr.length-1)
                }
            }
        }
        
        let max_size;

        if (to_compare.length > 0) {
            max_size = Math.max(...to_compare);
        } else {
            max_size = 99;
        }

        // let max_size = Math.max(...to_compare);
        console.log(max_size);
    
        if(max_size > size_chart.sizes.length){
            let len = size_chart.sizes.length
            console.log("result: ", size_chart.sizes[len-1]);
            return size_chart.sizes[len-1]
        } else {
            console.log("result: ", size_chart.sizes[max_size]);
           return size_chart.sizes[max_size]
        }
    }

    useEffect(() => {
        if (result || processStatus == 'process_error' || processStatus == 'process_success') {
            console.log("got result or error, clearing interval")
            clearInterval(intervalID);
            setIntervalID(null);
        }
    }, [result, processStatus]);

    useEffect(() => {
        if (processStatus == 'process_success') {
            const retrieveMeasurements = async () => {
                let url = 'https://database.esenca.app/get_employee_measurements'; // aici ar trebui sa fie un queryParam cu type
                let response = await fetch(url, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
                    body: JSON.stringify({
                        employee_id: employee_id,
                        measurement_type: props.type,
                        client_id: shop_id,
                    })
                });

                let res = await response.json();

                console.log(res);

                if (res['success']) {
                    console.log('setting user measurements');
                    const new_measurements = user_measurements;
                    new_measurements[props.type] = res['measurements'];
                    setUserMeasurements(new_measurements);
                    const new_result = result;
                    new_result[props.type] = recommendSize(size_charts[props.type][props.industry], new_measurements[props.type]);
                    console.log(new_result);
                    setResult(new_result);
                }
            }

            retrieveMeasurements();
        }
    }, [processStatus]);

    useEffect(() => {
        console.log(user_measurements)
        if (Object.keys(user_measurements).length > 0 && Object.keys(user_measurements).includes(props.type)) {
            console.log('I have the measurements, calculating size...');
            const new_result = result;
            new_result[props.type] = recommendSize(size_charts[props.type][props.industry], user_measurements[props.type]);
            console.log(new_result);
            setResult(new_result);
        }
    }, [user_measurements]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const renderWidgetStatus = (processStatus) => {
        switch (processStatus) {
            case "process_waiting":
                return(
                    <Grid container height={"100%"}>
                        <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'} height={'100%'} flexDirection={'column'}>
                            <QRCode 
                                value={widget_url[props.type]}
                                style={{ 
                                    height: "auto", maxWidth: "100%", alignSelf: "center" 
                                }}
                                viewBox={`0 0 256 256`}
                            />
                            <Typography textAlign={'center'} py={5} variant="h6">
                                Scan this QR Code with your phone to launch the app.
                            </Typography>
                        </Grid>
                    </Grid>
                );
                break;

            case "process_scanning":
                return(
                    <Grid container height={"100%"}>
                        <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'} height={'100%'} flexDirection={'column'}>
                            
                            <Box py={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress size={75}/>
                            </Box>
                            <Typography textAlign={'center'} py={5} variant="h6">
                                Your recommendation will show up here after you finish the scanning process.
                            </Typography>
                        </Grid>
                    </Grid>
                );

            case "process_success":
                return(
                    <Grid container height={"100%"}>
                        <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'} height={'100%'} flexDirection={'column'}>
                            <Typography textAlign={'center'} py={5} variant="h6">
                                Your size is
                            </Typography>
                            <Typography textAlign={'center'} py={5} variant="h1" sx={{ textTransform: 'uppercase' }}>
                                <b>{result[props.type]}</b>
                            </Typography>
                            <Typography textAlign={'center'} py={5} variant="h6">
                                for the item: {props.title}
                            </Typography>
                        </Grid>
                    </Grid>
                );

            case "process_error":
                return(
                    <Grid container height={"100%"}>
                        <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'} height={'100%'} flexDirection={'column'}>
                            <Typography textAlign={'center'} py={5} variant="h6">
                                Looks like you didn't finish the scanning process. Please refresh and try again.
                            </Typography>
                        </Grid>
                    </Grid>
                );
        
            default:
                break;
        }
    }

    // useEffect(() => {
    //     console.log(result);
    // }, [result]);

    return(
        <Grid item xs={12} md={12}>
            <Button variant="contained" onClick={handleClickOpenDialog}>
                Find my size
            </Button>
            {/* <Typography>{result[props.type] && ( <><Info /> We selected the recommended size for you</>) }</Typography> */}
            <Dialog
                fullScreen={fullScreen}
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle id="responsive-dialog-title" textAlign={'left'} >
                    Find my Size
                    <IconButton sx={{ float: 'right' }} onClick={handleCloseDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        height: '50vh'
                    }}
                >
                    {
                        renderWidgetStatus(processStatus)
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={() => setProcessStatus('process_waiting')}>
                        Initialize QR
                    </Button>
                    <Button onClick={() => setProcessStatus('process_scanning')}>
                        Start Process
                    </Button>
                    <Button onClick={() => setProcessStatus('process_success')}>
                        Show Result
                    </Button>
                    <Button onClick={() => setProcessStatus('process_error')}>
                        Show Error
                    </Button>
                    <Button onClick={() => { result == 'l' ? setResult('xl') : setResult('l') }}>
                        Set result
                    </Button>
                </DialogActions> */}
            </Dialog>
        </Grid>
    );
}