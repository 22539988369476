import { createContext, useContext, useEffect, useState } from "react";

import size_chart_clothing_workwear from '../assets/clothing/workwear/size_chart_clothing_workwear.json';
import size_chart_clothing_healthcare from '../assets/clothing/healthcare/size_chart_clothing_healthcare.json';
import size_chart_clothing_fashion from '../assets/clothing/fashion/size_chart_clothing_fashion.json';

import size_chart_footwear_workwear from '../assets/footwear/workwear/size_chart_footwear_workwear.json';
import size_chart_footwear_healthcare from '../assets/footwear/healthcare/size_chart_footwear_healthcare.json';
import size_chart_footwear_fashion from '../assets/footwear/fashion/size_chart_footwear_fashion.json';

import size_chart_handwear_workwear from '../assets/handwear/workwear/size_chart_handwear_workwear.json';
import size_chart_handwear_healthcare from '../assets/handwear/healthcare/size_chart_handwear_healthcare.json';
import size_chart_handwear_fashion from '../assets/handwear/fashion/size_chart_handwear_fashion.json';

export const Context = createContext();

// const clothing = {
//   "bottom": 99.91437401925265,
//   "chest": 101.8292337471939,
//   "email": "mihai@esenca.app",
//   "first_name": "Miha",
//   "gender": "male",
//   "height": 180,
//   "last_name": "Petre",
//   "left_ankle": 22.803608225303854,
//   "left_arm": 62.132279628721356,
//   "left_biceps": 34.3613217604944,
//   "left_calf": 38.4216116419727,
//   "left_forearm": 30.681350468379076,
//   "left_thigh": 60.2888404063007,
//   "left_wrist": 17.779421380295044,
//   "leg_length": 90,
//   "neck_median": 38.87564770430943,
//   "right_ankle": 22.803608225303854,
//   "right_arm": 62.132279628721356,
//   "right_biceps": 34.3613217604944,
//   "right_calf": 38.4216116419727,
//   "right_forearm": 30.681350468379076,
//   "right_thigh": 60.2888404063007,
//   "right_wrist": 17.779421380295044,
//   "shoulder_to_hip": 52.571253121856245,
//   "timestamp": 1698845029282,
//   "waist": 88.33325110848126,
//   "weight": 80
// };

function create_UUID(type) {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  var rtw = type;
  uuid = rtw.concat(uuid);

  if (type == 'demo') {
    window.sessionStorage.setItem('user_id', uuid);
  } else if (type == 'foot') {
    window.sessionStorage.setItem('foot_user_id', uuid);
  } else if (type == 'hand') {
    window.sessionStorage.setItem('hand_user_id', uuid);
  }
  
  return uuid;
}

function ContextProvider({ children }) {
    const [user_id, setUserId] = useState(window.sessionStorage.getItem('user_id') ? window.sessionStorage.getItem('user_id') : create_UUID('demo'));
    const [hand_user_id, setHandUserId] = useState(window.sessionStorage.getItem('hand_user_id') ? window.sessionStorage.getItem('hand_user_id') : create_UUID('hand'));
    const [foot_user_id, setFootUserId] = useState(window.sessionStorage.getItem('foot_user_id') ? window.sessionStorage.getItem('foot_user_id') : create_UUID('foot'));
    const [result, setResult] = useState(window.sessionStorage.getItem('result') ? JSON.parse(window.sessionStorage.getItem('result')) : {});
    const [user_measurements, setUserMeasurements] = useState(window.sessionStorage.getItem('user_measurements') ? JSON.parse(window.sessionStorage.getItem('user_measurements')) : {});
    

    const shop_id = "6sWPrXEB1phILMbRIq1jIblJYWA2";

    const widget_url = {
      'clothing': "https://widget.esenca.app/base/esenca?shop_id=" + shop_id + "&uid=" + user_id,
      'footwear': "https://footwear.esenca.app/?employee_id=" + foot_user_id,
      // 'footwear': "https://footwear-widget--test-xgw6lrr3.web.app/?employee_id=" + foot_user_id,
      'handwear': "https://handwear.esenca.app/?employee_id=" + hand_user_id,
      // 'handwear': "https://footwear-widget--test-xgw6lrr3.web.app/?employee_id=" + hand_user_id,
    }

    const size_charts = {
      'clothing': {
        'workwear': size_chart_clothing_workwear,
        'healthcare': size_chart_clothing_healthcare,
        'fashion': size_chart_clothing_fashion
      },
      'footwear': {
        'workwear': size_chart_footwear_workwear,
        'healthcare': size_chart_footwear_healthcare,
        'fashion': size_chart_footwear_fashion
      },
      'handwear': {
        'workwear': size_chart_handwear_workwear,
        'healthcare': size_chart_handwear_healthcare,
        'fashion': size_chart_handwear_fashion
      },
    }

    const shared_props = { 
      widget_url,
      shop_id,
      size_charts,

      user_id, 
      setUserId,

      hand_user_id,
      setHandUserId,
      foot_user_id,
      setFootUserId,
      
      result,
      setResult,

      user_measurements,
      setUserMeasurements,
    };

    useEffect(() => {
      console.log('result is: ', result);
    }, [result])
  
    return (
      <Context.Provider value={shared_props}>
        {children}
      </Context.Provider>
    );
  }
  
  function useShopContext() {
    const context = useContext(Context);
    if (!context) {
      throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
  }
  
  export { ContextProvider, useShopContext };