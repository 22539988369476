import {
    Grid
} from "@mui/material";

import NavBar from "../NavBar";
import ItemPage from "../ItemPage";
import TabPanel from "../TabPanel";

import workwear_image_0 from "../../assets/handwear/test_image_handwear.jpg";
import workwear_image_1 from "../../assets/handwear/workwear/handwear-workwear-1.jpg";
import workwear_image_2 from "../../assets/handwear/workwear/handwear-workwear-2.jpg";
import workwear_image_3 from "../../assets/handwear/workwear/handwear-workwear-3.jpg";
import workwear_logo from "../../assets/handwear/workwear/ironclad_logo.png";

import health_image_0 from "../../assets/handwear/healthcare/handwear-health-0.webp";
import health_image_1 from "../../assets/handwear/healthcare/handwear-health-1.webp";
import health_image_2 from "../../assets/handwear/healthcare/handwear-health-2.webp";
import health_image_3 from "../../assets/handwear/healthcare/handwear-health-3.webp";
import health_logo from "../../assets/handwear/healthcare/handwear-health-logo.webp";

import fashion_image_0 from "../../assets/handwear/fashion/handwear-fashion-0.png";
import fashion_image_1 from "../../assets/handwear/fashion/handwear-fashion-1.png";
import fashion_image_2 from "../../assets/handwear/fashion/handwear-fashion-2.png";
import fashion_image_3 from "../../assets/handwear/fashion/handwear-fashion-3.png";
import fashion_logo from "../../assets/handwear/fashion/handwear-fashion-logo.png";

import { useState } from "react";

export default function Handwear(props) {

    const workwearItem = {
        images: [ workwear_image_0, workwear_image_1, workwear_image_2, workwear_image_3 ],
        title: "COMMAND™ IMPACT CUT A6",
        description: "Reinforced Command™ Touchscreen Synthetic Suede Padded Palm, Breathable Polyester Knit Back of Hand, 360° Cut A5 HPPE Blend Liner, TPR Back of Hand and Thumb Impact Protection, Thumb Saddle Reinforcement",
        price: '29.99',
        logo: workwear_logo,
    }
    
    const healthcareItem = {
        images: [ health_image_0, health_image_1, health_image_2, health_image_3 ],
        title: 'Heritage Ring',
        description: 'A really smart ring. Meet the most innovative, stylish wearable on the market. Your Oura Ring tracks over 20 biometric signals, all from your finger.',
        price: '329.99',
        logo: health_logo,
    }

    const fashionItem = {
        images: [ fashion_image_0, fashion_image_1, fashion_image_2, fashion_image_3 ],
        title: "Engagement Ring in Platinum",
        description: "A true design masterpiece, the Tiffany® Setting is the world's most iconic engagement ring. Flawlessly engineered, the six-prong setting virtually disappears and allows the brilliant diamond to float above the band and into the light, resulting in a ring so beautiful it has been a symbol of the world’s greatest love stories for over 130 years.",
        price: '20,000.00',
        logo: fashion_logo,
    }


    const [currentTab, setCurrentTab] = useState(0);

    return(
        <>
            <NavBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <Grid container
                sx={{
                    height: '90vh'
                }}
                py={5}
            >
                <TabPanel value={currentTab} index={0}>
                    <ItemPage
                        smallImages={workwearItem.images} 
                        title={workwearItem.title} 
                        description={workwearItem.description}
                        price={workwearItem.price}
                        logo={workwearItem.logo}
                        type={'handwear'}
                        industry={'workwear'}
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    <ItemPage
                        smallImages={healthcareItem.images} 
                        title={healthcareItem.title} 
                        description={healthcareItem.description}
                        price={healthcareItem.price}
                        logo={healthcareItem.logo}
                        type={'handwear'}
                        industry={'healthcare'}
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <ItemPage
                        smallImages={fashionItem.images} 
                        title={fashionItem.title} 
                        description={fashionItem.description}
                        price={fashionItem.price}
                        logo={fashionItem.logo}
                        type={'handwear'}
                        industry={'fashion'}
                    />
                </TabPanel>
            </Grid>
        </>
    );
}