import { 
    BottomNavigation, 
    BottomNavigationAction,
    Grid,
    SvgIcon,
    Icon,
} from "@mui/material";

import {
    Add,
    Call
} from "@mui/icons-material";

import logo_svg from "../assets/general/esenca_logo_color_RGB_logotype.svg";

function LogoSvg() {
    return(
        <Icon sx={{ textAlign: 'center' }} fontSize={'large'}>
            <img src={logo_svg} height={'100%'} />
        </Icon>
    )
}

export default function Footer() {
    return (
        <BottomNavigation
            showLabels
            sx={{
                justifyContent: 'space-around',
                backgroundColor: '#a9adc5',
                borderRadius: '5px'
            }}
        >
            <BottomNavigationAction label="© ESENCA Digital Workwear "  sx={{ flexDirection: 'row', color: '#27336e', maxWidth: 300 }} /> 
            <BottomNavigationAction label="Discover More" icon={<Add fontSize={'large'}/>} sx={{ flexDirection: 'row', color: '#27336e', maxWidth: 300 }}/>
            <BottomNavigationAction label="Contact and social" icon={<Call fontSize={'large'}/>} sx={{ flexDirection: 'row', color: '#27336e', maxWidth: 300 }}/>
            
        </BottomNavigation>
    );
}