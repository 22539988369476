import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import LandingPage from "./pages/landing";
import Clothing from "./pages/clothing";
import Footwear from "./pages/footwear";
import Handwear from "./pages/handwear";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/handwear" element={<Handwear />} />
        <Route exact path="/footwear" element={<Footwear />} />
        <Route exact path="/clothing" element={<Clothing />} />
        <Route path="/" element={<LandingPage />}/>
      </Routes>
    </div>
  );
}

export default App;
