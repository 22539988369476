import React from 'react';

import {
    AppBar,
    Box,
    Button,
    ButtonGroup,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    SvgIcon,
    Tabs,
    Tab,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import logotype from '../assets/general/esenca_logo_color_RGB_logotype.png';
import { ReactComponent as MySvg } from '../assets/general/esenca_logo_monochrome_negative_RGB.svg';
import { useNavigate } from 'react-router-dom';

const pages = ['Clothing', 'Footwear', 'Handwear'];

export default function NavBar(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const nav = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return(
        <AppBar position='static'>
            <Container maxWidth='xxl'>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex'}, justifyContent: 'space-between'}}>
                        <Box>
                            <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={() => nav('/')}
                            >
                                <MySvg style={{ width: '175px', padding: 5 }}/>
                            </IconButton>
                        </Box>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block' },
                            }}
                        >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                        </Menu>
                        <Box aria-label="outlined button group"
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                verticalAlign: 'middle'
                            }}
                        >
                            {/* <Button size={'large'} color='secondary' variant="text">Workwear</Button>
                            <Button size={'large'} color='secondary' variant="text">Healthcare</Button>
                            <Button size={'large'} color='secondary' variant="text">Fashion</Button> */}
                            <Tabs 
                                value={props.currentTab} 
                                onChange={(e, newValue) => { props.setCurrentTab(newValue) }} 
                                centered
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: '#f0f0f0',
                                        marginBottom: 5
                                    }
                                }}
                            >
                                <Tab label='Workwear' sx={{ '&.Mui-selected': { color: '#f0f0f0' } }}/>
                                <Tab label='Healthcare' sx={{ '&.Mui-selected': { color: '#f0f0f0' } }}/>
                                <Tab label='Fashion' sx={{ '&.Mui-selected': { color: '#f0f0f0' } }}/>
                            </Tabs>
                        </Box>

                        <Box aria-label="outlined button group"
                            sx={{
                                display: 'flex',
                                alignContent: 'center'
                            }}
                        >
                            {/* <Button size={'large'} color='secondary' variant="text">Men</Button>
                            <Button size={'large'} color='secondary' variant="text">Women</Button> */}
                            <Button size={'large'} color='secondary' variant="text">Contact Us</Button>
                            <Button size={'large'} color='secondary' variant="text">Website</Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}