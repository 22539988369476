import { 
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";

import {
    ArrowDownward,
    ArrowUpward,
    StarBorder
} from "@mui/icons-material";
import { useEffect, useState } from "react";

import Widget from "./Widget";
import { useShopContext } from "../context";

export default function ItemPage(props) {
    const { 
        result,
        size_charts
    } = useShopContext();

    const sizes = size_charts[props.type][props.industry]['sizes'];

    const [currentImage, setCurrentImage] = useState(0);
    const [currentSize, setCurrentSize] = useState(null);

    const handlePrevImage = () => {
        if (currentImage > 0) {
            let newImageId = currentImage - 1;
            setCurrentImage(newImageId);
        }
    }

    const handleNextImage = () => {
        if (currentImage < props.smallImages.length - 1) {
            let newImageId = currentImage + 1;
            setCurrentImage(newImageId);
        }
    }

    const handleSizeSelect = (idx) => {
        setCurrentSize(idx);
    }

    useEffect(() => {
        if (Object.keys(result).includes(props.type)) {
            // console.log(result, props.type);
            // console.log(sizes, result[props.type])
            // console.log('setting size to: ', sizes.indexOf(result[props.type]));
            setCurrentSize(sizes.indexOf(result[props.type]));
        }
    }, [result]);

    return(
        <Grid container spacing={15}>
            <Grid item xs={12} md={7}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={5}>
                            {
                                props.smallImages.map((image, idx) => (
                                    <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            {
                                                idx == 0 && (
                                                    <IconButton size="large" sx={{ paddingBottom: 2 }} onClick={handlePrevImage}> 
                                                        <ArrowUpward />
                                                    </IconButton>
                                                )
                                            }
                                            <Box
                                                component='img'
                                                src={image}
                                                maxHeight={150}
                                                border={currentImage == idx && ("1px solid black")}
                                                onClick={() => setCurrentImage(idx)}
                                                sx={{
                                                    '&:hover': {
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                            />
                                            {
                                                idx == props.smallImages.length - 1 && (
                                                    <IconButton size="large" sx={{ paddingTop: 2 }} onClick={handleNextImage}>
                                                        <ArrowDownward />
                                                    </IconButton>
                                                )
                                            }
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <Box
                                component='img'
                                src={props.smallImages[currentImage]}
                                width={'110%'}
                            />
                        </Card>
                        
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h3">
                            <b>{props.title}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box 
                            component={'img'}
                            src={props.logo}
                            maxHeight={'100px'}
                            maxWidth={'300px'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StarBorder sx={{ color: 'black', fontSize:20, fontWeight:'700'}}/>
                        <StarBorder sx={{ color: 'black', fontSize:20, fontWeight:'700'}}/>
                        <StarBorder sx={{ color: 'black', fontSize:20, fontWeight:'700'}}/>
                        <StarBorder sx={{ color: 'black', fontSize:20, fontWeight:'700'}}/>
                        <StarBorder sx={{ color: 'black', fontSize:20, fontWeight:'700'}}/>
                    </Grid>
                    <Grid item xs={12} md={12} paddingRight={5}>
                        <Typography variant="body2" sx={{ textJustify: 'inter-word' }}>{props.description}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5">
                            $ {props.price}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} height={'20vh'} paddingRight={5}>
                        <List
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            {
                                sizes.map((size, idx) => (
                                    <ListItem 
                                        key={idx}
                                        sx={{
                                            width: '10%',
                                            marginLeft: 1,
                                            marginRight: 1,
                                            textAlign: 'center',
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        <ListItemText>
                                            <Button 
                                                variant={currentSize == idx ? 'contained' : 'outlined'}
                                                onClick={() => handleSizeSelect(idx)}
                                            >
                                                {size}
                                            </Button>
                                        </ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid>
                    <Widget title={props.title} type={props.type} industry={props.industry}/>
                    <Grid item xs={12} md={12}>
                        <Button variant="contained" disabled>
                            Add to Cart
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    );
}