import {
    Box,
    Grid,
} from "@mui/material";

import logo from "../../assets/general/esenca_logo_color_RGB.png";
import landing_clothing from "../../assets/clothing/test_image_workwear.png";
import landing_footwear from "../../assets/footwear/workwear/footwear-workwear-0.jpg";
import landing_handwear from "../../assets/handwear/test_image_handwear.jpg";

import PhotoCard from "./components/PhotoCard";

import { useNavigate } from "react-router-dom";
import ProductCategories from "./components/ProductCategories";
import NavBar from "../NavBar";
import Footer from "../Footer";

export default function LandingPage() {

    const nav = useNavigate();

    return (
        <Grid 
            container 
            spacing={2}
            sx={{
                height: "100vh",
                width: "100vw",
            }}
        >
            <Grid item xs={12} md={12} lg={12}
                sx={{
                    height: "10vh"
                }}
            >
                <NavBar setCurrentTab={() => nav('/clothing')}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}
                sx={{
                    height: "80vh",
                    display: 'flex',
                }}
            >
                <Grid container
                    sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Grid 
                        item xs={12} md={4} lg={4} 
                        sx={{
                            height: "100%",
                            width: "100%",
                            borderRadius: '5px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <ProductCategories image={landing_clothing} title={'Clothing'} onClick={() => nav('/clothing')}/>
                    </Grid>
                    <Grid 
                        item xs={12} md={4} lg={4}
                        sx={{
                            height: "100%",
                            width: "100%",
                            borderRadius: '5px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <ProductCategories image={landing_footwear} title={'Footwear'} onClick={() => nav('/footwear')}/>
                    </Grid>
                    <Grid 
                        item xs={12} md={4} lg={4}
                        sx={{
                            height: "100%",
                            width: "100%",
                            borderRadius: '5px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <ProductCategories image={landing_handwear} title={'Handwear'} onClick={() => nav('/handwear')}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Footer />
            </Grid>
        </Grid>
    )
}